.footer{
padding: 12em 2em;
display:flex;
}
.footer>hr{
    margin-top: -190px;
}
.social-media{
display:flex;
flex-direction: row;
gap:4em;
position: absolute;
top: 3400px;
left:550px;
}
.social-media>img{
    width: 2em;
    height:2em;
    cursor: pointer;


}
.social-media>img:hover{
color:orange;
}
.logosocial{
   position: absolute;
   top:3450px;
   left:580px;
   margin-top: 30px;

}
.logosocial>img{
    width:12em;
    height:3em;
    cursor: pointer;
}

.blur-f-1{
    width:26em;
    height: 12em;
    top: 3450px;
    left: 15%;
    filter:blur(200px);
    background-color: orange;
}
.blur-f-2{
    width:26em;
    height: 12em;
    top: 3450px;
    right: 15%;
    filter:blur(200px);
    background-color: orange;
}

@media screen and (max-width:768px){
    .footer{
        display:flex;
        flex-direction: column;
    }
    .footer>hr{
display:flex;
flex-direction: row;
width:15em;
    }
    .social-media>img{
        display: flex;
        flex-direction: column;
        position:relative;
        top:5px;
    }
    
}