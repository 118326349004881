.header{
    display:flex;
    justify-content:space-between;
}


.logo{
    height:3em;
    width:10em;
   
}

.list{
    list-style: none;
    display:flex;
    gap:2em;
    color:white;
}
.list>li:hover{
    cursor: pointer;
    color:orange;
}
@media screen and (max-width:768px){
    .header>:nth-child(2){
        position:fixed;
        right:2em;
        z-index: 99;
    }
    .list{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2em;
    }
}
