.raisons{
    display:flex;
    padding:2em;
justify-content: space-between;

}
.l-raisons{
    display:flex;
    gap:1em;
    border-radius: 1em;

}
.l-raisons>:nth-child(1){
    width:200px;
    height: 500px;
    border:solid orange;
    border-radius: 1em;

}
.images{
    display:flex;
    flex-direction: column;
    gap:1em;
    position: relative;
    
}
.image2{
   height:300px;
   width:500px;
   border:solid orange;
   border-radius: 1em;
}
.image-3-4{
    display:flex;
    gap:1em;
    height:180px;
}
.image-3-4>:nth-child(1){
    width:250px;
    border:solid orange;
    border-radius: 1em;
}
.image-3-4>:nth-child(2){
    width:235px;
    border:solid orange;
    border-radius: 1em;
}
.r-raisons{
    display:flex;
    flex-direction: column;
  position: absolute;
  right:160px;
  gap:2em;
  
}

.r-raisons>:nth-child(1){
    color:var(--orange);
    font-size:29px;
}
.why{
    color:white;
    font-size: 45px;
    font-weight: bold;
}
.img-span{
    display:flex;
    flex-direction: column;
    justify-content: center;
    gap:1em;
}

.img-span div {
color:white;
display:flex;
gap:1em;
align-items: center;
}
.img-span div img {
    width:40px;
}
.our-partners{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
   gap:1em;
}
.our-partners span{
    color:var(--gray);
    font-size: 20px;
    font-weight: bold;
}
.partners{
display:flex;
gap:2em;
}
@media screen and (max-width:768px) {
.raisons{
        flex-direction: column; 
    }
    .l-raisons{
        grid-auto-rows: auto;
        overflow:hidden;
    }
    .l-raisons>:nth-child(1){
        width:7em;
        height: 17em;
    }
    .l-raisons>div>:nth-child(1){
        width:8em;
        height: 8em;
    }
    .image-3-4>:nth-child(1){
        width:8em;
        height:8em;
    }
.r-raisons{
    display:flex;
    flex-direction: column;
    position: relative;
    left:15px;
}
}
