.testimonials{
    display:flex;
    padding:0 2em;
    gap:1em;
}

.l-test{
    display:flex;
    flex-direction: column;
    gap:2em;
color:white;
flex:1 1;

}
.l-test>:nth-child(1){
    color:orange;
    font-weight: bold;font-size: 18px;
}
.l-test>:nth-child(2){
    font-size: 53px;
    font-weight: bold;
}
.l-test>:nth-child(3){
    font-size: 53px;
    font-weight: bold;
}
.reviews{
    font-family:'Courier New', Courier, monospace;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

.index-span>:nth-child(1){
    color:var(--orange);
}
.r-test{
    flex:1;
    position: relative;
}

.r-test>img{
    position: absolute;
    width:17em;
    height: 20em;
    object-fit: cover;
   right:8em;
   top:2em;

   
}
.r-test>:nth-child(1){
    position: absolute;
    width:17em;
    height: 20em;
    border:2px solid orange;
    right: 150px;
    top:0.9em;
    background-color: transparent;
}
.r-test>:nth-child(2){
    position: absolute;
    width: 17em;
height:20em;
background-color: var(--orange);
top:3em;
right:110px;
}
.arrows{
    position: absolute;
    top:19em;
    right:520px;
    width: 2em;
    height: 2em;
    display: flex;
    gap:1em;
}
.arrows>img{
    cursor: pointer;
}
@media screen and (max-width:768px) {
    .testimonials{
        flex-direction: column;
    }
    .l-test>:nth-child(2)
    ,.l-test>:nth-child(3){
        font-size: xx-large;
    }
    .r-test{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:2em;

    }
    .r-test>div{
        position: relative;
        display: none;
    }
    .r-test>img{
        top:0;
        right:0;
        position: relative;
    }
 .r-test>:last-child{
    display:block;
    top:0;
    left:0;
 }

.r-test>:last-child{
    display: block;
    top:0;
    left:0;
    justify-content: center;
    align-items: center;

}
.arrows{
    display:flex;
    gap:1em;
    flex-direction: row;
}
.arrows>img{

    width:1em;
    gap:1em;
    height:2em;
}

}
