.programs{
    display:flex;
    flex-direction: column;
    padding: 0 2em;
}

.p-header{
    display:flex;
    gap:5em;
    color:white;
    justify-content: center;
}


.p-header>span{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
    text-transform: uppercase;
    fill: white;
    font-weight: bold;
}
.programs-category{
    display:flex;
    margin-top: 30px;
    gap: 2em;

    

}
.category{
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: gray;
  justify-content: space-between;
    padding:2em;
    color:white
}
.category>:nth-child(1){
    fill:white;
    width: 2em;
    height: 2em;

}

.category>span:nth-of-type(1){
    font-weight: bold;
    font-size: 1em;
}
.category>span:nth-of-type(1){
   font-size: 0.9rem;
   line-height: 25px;
}

.join-us{
    display:flex;
    gap:2em;

}
.join-us>img{
    width: 1em;
}

.category:hover{
    cursor: pointer;
    background-color: var(--orange);
    
}
@media screen and (max-width:768px){
    .p-header{
        flex-direction: column;
        gap:1em;

        justify-content: center;
        align-items: center;
        margin-top: 2em;
    }
    .p-header>span{
        font-size: x-large;
    }
    .programs-category{
        display:flex;
        flex-direction: column;
    }
}

