.hero{
    display:flex;
    justify-content: space-between;
}

.hero-l{
  
    padding:2em;
    padding-top:1.5em;
     flex:3;
     display:flex;
     flex-direction: column;
     gap: 2em;
}
.hero-r{
   flex:1;
   background-color: var(--orange);
   position: relative;

}

.the-best-add{
    margin-top: 1em;
    border-radius: 2em;
    background-color: #363d42;
    padding: 1em;
    width: fit-content;
    text-transform: uppercase;
    color:white;
position:relative;
display:flex;
justify-content: flex-start;
align-items: center;

}
.the-best-add>span{
z-index: 2;
}
.the-best-add>div{
position: absolute;
width:5em;
height: 80%;
background-color: var(--orange);
left:8px;
border-radius: 3em;
z-index: 1;
}
.hero-text{
    margin-top: 10px;
    font-size:70px;
    color: white;
    font-family:Arial,sans-serif;
    font-weight: bold;
    text-overflow: inherit;
    display:flex;
    flex-direction: column;
}
.shape{
    
    color:transparent;
}
.hero-text>div:nth-of-type(3){
font-size:19px;
font-weight:200;
font-family: Arial, Helvetica, sans-serif;
text-transform:none;
margin-top: 20px;
}

.figures{
    display:flex;
    gap:2em;
    margin-bottom: 0.01em;
    color:white;
 
    
}
.figures>div>h3{
    margin-bottom: 0.2px;
    font-size: 25px;
    font-weight: 300;
}


.figures>div>p{
    margin-top: 1px;
    font-size: 19px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 50;
    opacity: 50%;
    margin-bottom: 1px;
}

.button{
    margin-top: 0.2px;
    text-decoration: none;
    background-color: var(--orange);
    border: none;
    padding: 12px 30px;
    color:white;
    font-size: 15px;
margin-right: 15px;
font-weight: bold;

}

.button-2{
    background-color:transparent;
    padding: 10px 30px;
    color:white;
  border:4px solid var(--orange) ;
  font-weight: bold;
  transition: 300ms all;

}

.button-2:hover{
    cursor: pointer;
}
.button:hover{
    cursor: pointer;
}

.hero-r>.button{
background-color:white;
color: black;
position: absolute;
right:40px;
top:35px;
padding: 10px;
}
.heart-rate{
    display:flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--darkGrey);
    padding:19px;
    align-items: center;
    top:7em;
    right: 4em;
    gap:1em;
    width: fit-content;
    align-items: start;
    border-radius: 1em;
}
.heart-rate>img{
 width:40%;


}
.heart-rate>span:nth-of-type(1){
    color:var(--gray);
    font-size: 18px;
}
.heart-rate>span:nth-of-type(2){
    color:white;
    font-size: 20px;
}

.heroimage{
position:absolute;
width:23em;
top:7em;
right:8em;
}
.heroback{
    position: absolute;
   top: 3em;
   width: 15em;
   right:19rem;
   z-index: -1;

}


.calories{
    display:flex;
    align-items: center;

    gap:2em;
    position: absolute;
    top:32em;
    border-radius: 5px;
    right:28em;
    background-color: var(--caloryCard);
    padding: 0.5em;
    width: fit-content;
}
.calotext{
    display: flex;
    flex-direction: column;
    font-size: 19px;
    gap:1em;
    align-items: center;

}

.calotext>span:nth-child(1){
    color:var(--gray)
}
.calotext>span:nth-child(2){
    color:white;
    font-size:29px;
}

.blur-b{
   width:23em;
   height: 30em;
   left:0;
}

@media screen and (max-width:768px){
.hero{
    display:flex;
    flex-direction: column;
}
.blur-b{
    width: 14em;
}
.the-best-add{
    margin-top: 0;
    align-self: center;
    transform: scale(0.9);
    font-size: small ;
}
.hero-text{
    font-size: xx-large;
    justify-content: center;
    align-items: center;
}
.hero-text>div:nth-of-type(3){
    font-size: small;
    font-weight: 200;
letter-spacing: 1px;
text-align: center;
}

.figures>div>h3{
    font-size:large;
}
.figures>div>p{
    font-size: small;
}
.btn-btn{
    display:flex;
    justify-content: center;
    align-items: center;

}
.button{
    margin-top: 0.2px;
    text-decoration: none;
    background-color: var(--orange);
    border: none;
    padding: 3px 18px;
    color:white;
    font-size: 15px;
margin-right: 15px;
font-weight: bold;

}
.button-2{
    margin-top: 0.2px;
    text-decoration: none;
    background-color: var(--orange);
    border: none;
    padding: 3px 18px;
    color:white;
    font-size: 15px;
font-weight: bold;
text-align:center;
text-align-last: center;
flex-direction: column;

}
.hero-r{
    position: relative;
    background: none;
}
.heart-rate{
    left:1em;
    top:2em;
}
.calories{
position: relative;
top:5em;
left:2em;
}
.calories>img{
    width:2em;
}

.calories>div>:nth-child(2){
    color:white;
    font-size: 1em;
}
.heroimage{
    position: relative;
    width:15em;
    left: 7em;
    top:4em;
    align-self: center;
}
.heroback{
    width:15em;
    left:2em;
    top:0;
}
}