.plans{
    margin-top: -4em;
    display:flex;
    padding: 0 2em;
    flex-direction: column;
    position: relative;
    gap:4em;
}

.plan-header{
    display:flex;
    flex-direction: row;
    justify-content: center;
    gap:1em;
    font-size: 45px;
    font-weight: bold;
    color:white;
}

.plansdata{

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap:2em;

}
.plan{
    display:flex;
    flex-direction: column;
    gap:2em;
   
    background-color:var(--caloryCard);
   width:15em;
   color:white;
   align-items: flex-start;
   padding: 1.5em;
margin-bottom: 20px;
}
.plan:nth-child(2){
    background-color: var(--orange);
    transform:scale(1.1);

}

.plan>svg{

    fill:orange;
width:2em;
height: 2em;


}
.plan>:nth-child(2){
    font-weight: bold;
    margin-left: 5px;

}
.plan>:nth-child(3){
font-size: 49px;
margin-left: 5px;
}
.features{
    display:flex;
    flex-direction: column;
    gap:1em;
    align-content: center;
    margin-left: 5px;
}
.feature>img{
    width:1em;
    height: 1em;
}
.feature{
    display: flex;
    gap:1em;
    align-items: center;
}
.button{
background-color: white;
color:black;
}
.plan:nth-child(2)>svg{
    fill:white;
}
.plan:nth-child(2)>button{
    color:orange;
}

.blur-a{
    width:32em;
    height: 30em;
    left:0;
    top:4em;
}
.blur-c{
    width:32em;
    height: 30em;
    right:0;
    top:4em;
}

@media screen and (max-width:768px) {
    .plan-header{
        display:flex;
        flex-direction: column;
        font-size: x-large;
        justify-content: center;
        align-items: center;
    }
    .plansdata{
        display: flex;
        flex-direction: column;
    }
    .plansdata>:nth-child(2){
        transform: none;
    }
}
