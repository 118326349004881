.join{
    display: flex;
    justify-content: space-between;
    padding: 2em 2em;
    gap:3em;
    position: relative;
}

.l-join{
    font-size: 55px;
    font-weight: bold;
    color:white;
}
.l-join>hr{
    width:7em;
    padding:0.01em;
    position: absolute;
    bottom: 2.5em;
    border:solid 1px var(--orange);
    background-color: var(--orange);
}
.r-join{
    display:flex;
right:10em;
top:195em;

}
.form-email{
    background-color:gray ;
    padding: 1.5em;
}
.form-email>input{
    width:fit-content;
    height: 3em;
    width:18em;
    background-color:transparent;
    border: none;
    color:var(--lightgray);
    outline: none;
}
::placeholder{
    color:var(--lightgray)
}
.form-email>button{
    background-color: var(--orange);
    color:white;
}
@media screen and (max-width:768px){
    .join{
        display:flex;
        flex-direction: column;
        gap: 1em;
    }
    .l-join{
        font-size: x-large;
        text-align: center;
        flex-direction: column;
    }
    .l-join>hr{
        position:relative;

    }
    .r-join{
        display: flex;
        flex-direction: column;
    }
}